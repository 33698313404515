import React from "react";
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import HeaderLanding from "../components/header-landing";
import Section6 from "../components/section6";
import Footer from "../components/footer";
import "./home.css";
import gamut_statistics from "../components/docs/Gamut-statistics.jpg";
import kava_logo from "../components/docs/Kava-Logo.svg";
import wolf_logo from "../components/docs/wolf-logo-color.svg";
import tradfi_logo from "../components/docs/tradfi2defi.svg";

const Home = (props) => {
  const ref = useRef();
  const { events } = useDraggable(ref);

  return (
    <div className="home-container">
      <Helmet>
        <title>Gamut Exchange</title>
        <meta property="og:title" content="Gamut Exchange" />
        <link id="favicon" rel="icon" href="/playground_assets/Gamut-logo-bow.svg" type="image/x-icon" />
      </Helmet>
      <div className="home-section1">
        <HeaderLanding rootClassName="header-landing-root-class-name"></HeaderLanding>
        <span className="home-text">
          {" "}
          Gamut’s Algorithmic AMM Generates 33% More Profit Per Trade on Average
        </span>
        <div className="home-app">
          <button className="home-button button">
            <Link to="/innovation">Learn How</Link>
          </button>
        </div>
      </div>
      <div className="home-section2">
        <div className="home-container01">
          <h1 className="home-text02">Capital Efficiency Redefined</h1>
          <span className="home-text03">
            Gamut’s proprietary algorithms maximize your position’s upside
            potential while solving complex DeFi challenges like impermanent
            loss, providing you with the next generation of decentralized
            marketplaces purpose-built for crypto.
          </span>
          <div className="home-container02">
            <div className="home-app1">
              <a href="https://docs.gamut.exchange/introduction/welcome-to-the-gamut-docs" target="_blank" className="home-button1 button">
                <span>Docs</span>
              </a>
            </div>
            <div className="home-app2">
              <a href="https://app.gamut.exchange" target="_blank" className="home-button2 button">
                <span>Launch App</span>
              </a>
            </div>
          </div>
        </div>
        <div className="home-container03">
          <h1 className="home-text06">Innovative Features</h1>
          <div className="home-container04">
            <div className="home-container05"></div>
            <span className="home-text07">
              Intelligent Automated Market Making
            </span>
          </div>
          <div className="home-container06">
            <div className="home-container07"></div>
            <span className="home-text08">Volatility Positive Algorithms</span>
          </div>
          <div className="home-container08">
            <div className="home-container09"></div>
            <span className="home-text09">Minimal Impermanent Loss</span>
          </div>
          <div className="home-container10">
            <div className="home-container11"></div>
            <span className="home-text10">Ultra-Low Trading Fees</span>
          </div>
          <div className="home-container12">
            <div className="home-container13"></div>
            <span className="home-text11">
              <span>Secure and Audited Proprietary Smart Contracts</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="home-section-statistics">
        <div className="home-container04 justify-center mb-7">
          <h1 className="home-text35">Market Making Unleashed</h1>
        </div>
        <img src={gamut_statistics} alt="gamut_statistics" className="statistics-image" />
      </div>
      <div className="home-section3">
        <h1 className="home-text14">Most important to our Users</h1>
        <div className="home-container14">
          <div
            className="home-container15 flex max-w-xl space-x-3 overflow-x-scroll scrollbar-hide"
            {...events}
            ref={ref}
          >
            <div className="home-container16">
              <span className="home-text15">
                <span>01</span>
                <br></br>
              </span>
              <h1 className="home-text18">Efficient</h1>
              <span className="home-text19">
                Profit from volatility while minimizing impermanent loss using
                the Gamut AMM.
              </span>
            </div>
            <div className="home-container17">
              <span className="home-text20">
                <span>02</span>
                <br></br>
              </span>
              <h1 className="home-text23">Innovative</h1>
              <span className="home-text24">
                Proven proprietary calculations provide fairness and mutual
                benefit using rational economic incentives.
              </span>
            </div>
            <div className="home-container19">
              <span className="home-text25">
                <span>03</span>
                <br></br>
              </span>
              <h1 className="home-text28">Non-Custodial</h1>
              <span className="home-text29">
                Gamut uses smart contracts to ensure you always maintain 100%
                control of your assets.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </div>
            <div className="home-container20">
              <span className="home-text30">
                <span>04</span>
                <br></br>
              </span>
              <h1 className="home-text33">Secure</h1>
              <span className="home-text34">
                Peer-review and audits future-proof Gamut for the next
                generation of DeFi users.
              </span>
            </div>
          </div>
          {/* <div className="home-container18">
            <div className="home-container19">
              <span className="home-text25">
                <span>03</span>
                <br></br>
              </span>
              <h1 className="home-text28">Non-Custodial</h1>
              <span className="home-text29">
                Gamut uses smart contracts to ensure you always maintain 100%
                control of your assets.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-container20">
              <span className="home-text30">
                <span>04</span>
                <br></br>
              </span>
              <h1 className="home-text33">Secure</h1>
              <span className="home-text34">
                Peer-review and audits future-proof Gamut for the next
                generation of DeFi users.
              </span>
            </div>
          </div> */}
        </div>
      </div>
      <div className="home-section4">
        <div className="home-container21">
          <h1 className="home-text35">Why use Gamut?</h1>
        </div>
        <div className="home-container22">
          <div className="home-container23">
            <div className="home-container24">
              <div className="home-container25">
                <h1 className="home-traders">
                  <span>Trade</span>
                  <br></br>
                </h1>
                <span className="home-text38">
                  Benefit from the non-custodial nature of a DEX and the
                  competitive trading fees of a CEX, all while enjoying the
                  deepest liquidity found anywhere in DeFi.
                </span>
              </div>
              <div className="home-container26">
                <h1 className="home-traders1">
                  <span>Liquidity Providing</span>
                  <br></br>
                </h1>
                <span className="home-text41">
                  Take advantage of market volatility and avoid impermanent
                  loss, helping protect your assets as you provide the fuel that
                  powers the engine of DeFi.
                </span>
              </div>
            </div>
            <div className="home-container27">
              <div className="home-container28">
                <h1 className="home-traders2">Arbitrage</h1>
                <span className="home-text42">
                  Use Gamut’s proprietary algorithms for additional arbitrage
                  opportunities, helping align price and value in an often
                  volatile market.
                </span>
              </div>
              <div className="home-container29">
                <h1 className="home-traders3">Invest</h1>
                <span className="home-text43">
                  Maximize the value of your long-term assets by providing them
                  as liquidity in the Gamut AMM without fear of impermanent
                  loss.
                </span>
              </div>
            </div>
          </div>
          <div>
            <img
              alt="image"
              src="/playground_assets/frame.svg"
              className="home-image"
            />
          </div>
        </div>
      </div>
      <div className="home-section5">
        <h1 className="home-text44">Trusted Partners</h1>
        <div className="home-container30">
          <div className="home-container31">
            <div className="home-partner">
              <img src={kava_logo} alt="kava_logo" width={200} />
            </div>
            <a className="home-button3 button" href="https://www.kava.io/" target="_blank">Learn More 🠚</a>
          </div>
          <div className="home-container32">
            <div className="home-partner">
              <img src={wolf_logo} alt="wolf_logo" width={200} />
            </div>
            <a className="home-button3 button" style={{marginTop: 0}} href="https://www.wolfandco.com/" target="_blank">Learn More 🠚</a>
          </div>
        </div>
        <div className="home-container33">
          <div className="home-container34">
            <div className="home-partner">
              <img src={tradfi_logo} alt="tradfi_logo" width={130} />
            </div>
            <a className="home-button3 button" href="https://t.co/VyLUXb3zgJ" target="_blank">Learn More 🠚</a>
          </div>
          <div className="home-container35">
            <div className="home-partner mt-5">
              <p className="coming-soon">Coming Soon</p>
            </div>
          </div>
        </div>
      </div>
      <Section6></Section6>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  );
};

export default Home;
