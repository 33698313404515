import React from 'react'
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { Link } from "react-router-dom";

import { Helmet } from 'react-helmet'

import HeaderLanding from '../components/header-landing'
import Gamutbutton from '../components/gamutbutton'
import Section6 from '../components/section6'
import Footer from '../components/footer'
import './inovation.css'
import pdf from "../components/docs/Gamut Whitepaper 10.11.2022.pdf";

const Inovation = (props) => {
  const ref = useRef();
  const { events } = useDraggable(ref);
  
  return (
    <div className="inovation-container">
      <Helmet>
        <title>Innovation - Gamut Exchange</title>
        <meta
          property="og:title"
          content="Inovation - Gamut Exchange"
        />
      </Helmet>
      <div className="inovation-container01">
        <HeaderLanding rootClassName="header-landing-root-class-name1"></HeaderLanding>
        <span className="inovation-text">
          Gamut Fixes Crypto Volatility
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <span className="inovation-text01">
          Gamut approaches markets differently from other crypto projects. Our
          proprietary automated market maker is disrupting the industry, placing
          power back in the hands of liquidity providers and making the market
          more fair for everyone!
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <div className="inovation-app">
          <button className="inovation-button button">
            <a href="https://docs.gamut.exchange/introduction/welcome-to-the-gamut-docs" target="_blank">Read Docs</a>
          </button>
        </div>
      </div>
      <div className="inovation-container02">
        <h1 className="inovation-text03">
          <span>Capital Efficiency Redefined</span>
          <br></br>
        </h1>
        <div className="inovation-container03 flex max-w-xl overflow-x-scroll scrollbar-hide"
          {...events}
          ref={ref}
        >
          <div className="inovation-container04">
            <div className="inovation-container05">
              <div className="inovation-container06">
                <span className="inovation-text06">
                  <span>01</span>
                  <br></br>
                </span>
              </div>
              <div className="inovation-container07"></div>
            </div>
            <h1 className="inovation-text09">
              <span>Pool Creation</span>
              <br></br>
            </h1>
            <span className="inovation-text12">
              A ratio between the two assets is set by the pool creator once
              they provide the initial liquidity for the pool, allowing for
              unique trading opportunities.
            </span>
          </div>
          <div className="inovation-container08">
            <div className="inovation-container09">
              <div className="inovation-container10"></div>
              <div className="inovation-container11">
                <span className="inovation-text13">
                  <span>02</span>
                  <br></br>
                </span>
              </div>
              <div className="inovation-container12"></div>
            </div>
            <h1 className="inovation-text16">
              <span>Trade</span>
              <br></br>
            </h1>
            <span className="inovation-text19">
              Anyone can trade using Gamut’s liquidity pools for a
              market-competitive 0.1% fee per trade, saving traders and
              liquidity providers money and headache.
            </span>
          </div>
          <div className="inovation-container13">
            <div className="inovation-container14">
              <div className="inovation-container15"></div>
              <div className="inovation-container16">
                <span className="inovation-text20">
                  <span>03</span>
                  <br></br>
                </span>
              </div>
              <div className="inovation-container17"></div>
            </div>
            <h1 className="inovation-text23">Ratio adjustment</h1>
            <span className="inovation-text24">
              Gamut adjusts the asset ratios based on a trade’s size and
              direction. This adjustment impacts both assets, moving prices out
              of line with the broader crypto market and creating opportunity
              for traders.
            </span>
          </div>
          <div className="inovation-container18">
            <div className="inovation-container19">
              <div className="inovation-container20"></div>
              <div className="inovation-container21">
                <span className="inovation-text25">
                  <span>04</span>
                  <br></br>
                </span>
              </div>
            </div>
            <h1 className="inovation-text28">Arbitrage</h1>
            <span className="inovation-text29">
              {' '}
              Market forces bring Gamut’s prices back into line with the broader
              market, facilitating more trading activity and maximizing profits
              for liquidity providers.
            </span>
          </div>
        </div>
      </div>
      <div className="inovation-container22">
        <img
          alt="image"
          src="/playground_assets/rectangle%2040181-1500w.png"
          className="inovation-image"
        />
        <div className="inovation-container23">
          <h1 className="inovation-text30">Maximizing Profits</h1>
          <h1 className="inovation-text31">
            <span>Trade by Trade</span>
            <br></br>
          </h1>
          <span className="inovation-text34">
            Gamut’s proprietary ratios and price adjustments help users make the
            most of asset volatility. Think of ratio adjustments as the pool
            itself buying or selling a small share of its respective assets.
            This unique functionality of the Gamut automated market maker makes
            it possible for liquidity providers to maximize profit while
            extending minimal fees for traders.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <Gamutbutton rootClassName="gamutbutton-root-class-name"></Gamutbutton>
        </div>
      </div>
      <div className="inovation-container24">
        <div className="inovation-container25">
          <h1 className="inovation-text35">More Details</h1>
          <span className="inovation-text36">Read our Whitepaper</span>
        </div>
        <svg viewBox="0 0 1024 1024" className="inovation-icon">
          <path d="M917.806 229.076c-22.212-30.292-53.174-65.7-87.178-99.704s-69.412-64.964-99.704-87.178c-51.574-37.82-76.592-42.194-90.924-42.194h-496c-44.112 0-80 35.888-80 80v864c0 44.112 35.888 80 80 80h736c44.112 0 80-35.888 80-80v-624c0-14.332-4.372-39.35-42.194-90.924zM785.374 174.626c30.7 30.7 54.8 58.398 72.58 81.374h-153.954v-153.946c22.984 17.78 50.678 41.878 81.374 72.572zM896 944c0 8.672-7.328 16-16 16h-736c-8.672 0-16-7.328-16-16v-864c0-8.672 7.328-16 16-16 0 0 495.956-0.002 496 0v224c0 17.672 14.326 32 32 32h224v624z"></path>
          <path d="M736 832h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32z"></path>
          <path d="M736 704h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32z"></path>
          <path d="M736 576h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32z"></path>
        </svg>
        <div className="inovation-container26">
          <span className="inovation-text37">
            Learn about the underlying Math of Gamut&apos;s Algorithm
          </span>
          <div className="inovation-app1">
            <button className="inovation-button1 button">
              <a href={pdf} target="_blank">Whitepaper</a>
            </button>
          </div>
        </div>
      </div>
      <Section6 rootClassName="section6-root-class-name"></Section6>
      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default Inovation
