import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";

import "./style.css";
import Home from "./views/home";
import Inovation from "./views/inovation";

const App = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/innovation" element={<Inovation />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </HashRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
