import React from 'react'
import PropTypes from 'prop-types'
import Mailchimp from 'react-mailchimp-form'
import './section6.css'

const Section6 = (props) => {
  return (
    <div className={`section6-section6 ${props.rootClassName} `}>
      <h1 className="section6-text">{props.heading}</h1>
      <div className="section6-container">
        <Mailchimp
          action='https://gmail.us21.list-manage.com/subscribe/post?u=e216b6080a0aa5d1bf00a177f&amp;id=86c82c31a4&amp;f_id=00acc4e1f0'
          fields={[
            {
              name: 'EMAIL',
              placeholder: props.Input_placeholder,
              type: 'email',
              className: "section6-input input",
              required: true
            }
          ]}
          messages={
             {
                 sending: "Sending...",
                 success: "Thank you for subscribing!",
                 error: "An unexpected internal error has occurred.",
                 empty: "You must write an e-mail.",
                 duplicate: "Too many subscribe attempts for this email address",
                 button: "Subscribe"
             }
          }
          className='gamut-mailchimp'
        />
      </div>
    </div>
  )
}

Section6.defaultProps = {
  text: 'Subscribe',
  rootClassName: '',
  Input_placeholder: 'Enter your E-mail',
  heading: 'Learn about all gamut updates and news',
}

Section6.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  Input_placeholder: PropTypes.string,
  heading: PropTypes.string,
}

export default Section6
