import React, { useState } from "react";

import PropTypes from "prop-types";

import "./header-landing.css";
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";
import pdf from "./docs/Gamut Whitepaper 10.11.2022.pdf";

const HeaderLanding = (props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className={`header-landing-container ${props.rootClassName} `}>
        <div className="header-landing-inner flex items-center content-between">
          <div className="z-50 pl-2">
            <button>
            <Link to="/home">
            <img
              alt={props.image_alt}
              src={props.image_src}
              className="header-landing-image"
            />
            </Link>
            </button>
          </div>
          <div className="header-landing-nav">
            <button
              type="button"
              className="header-landing-button btmenue button"
            >
              <Link to="/home">{props.btInovation}</Link>
            </button>
            <button
              type="button"
              className="header-landing-button1 btmenue button"
            >
              <Link to="/innovation">{props.btBlog}</Link>
            </button>
            <button className="header-landing-button2 btmenue button">
              <a href={pdf} target="_blank">{props.btWhitepaper}</a>
            </button>
          </div>
          <div className="header-landing-container1">
            <div className="header-landing-app">
              <button className="header-landing-button3">
                <a href="https://app.gamut.exchange" target="_blank" className="header-landing-text">{props.text}</a>
              </button>
            </div>
            <div className="pl-3 z-50">
              <Hamburger toggled={isOpen} toggle={setOpen} color="#fff" />
            </div>

            {/* <div className="header-landing-app1">
          <svg
            color="white"
            viewBox="0 0 1024 1024"
            className="header-landing-icon"
          >
            <path
              d="M64 192h896v192h-896zM64 448h896v192h-896zM64 704h896v192h-896z"
              className=""
            ></path>
          </svg>
        </div> */}
          </div>
        </div>
      </div>
      {!!isOpen ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#000] z-10">
          <ul className="text-white flex justify-around flex-col pt-28 px-4">
            <li className="w-full no-underline my-4">
              <div className="px-10 py-3">
                <Link to="/home" className="w-full inline-block">
                  HOME
                </Link>
              </div>
            </li>
            <li className="w-full no-underline my-4">
              <div className="px-10 py-3">
                <Link to="/innovation" className="w-full inline-block">
                  INNOVATION
                </Link>
              </div>
            </li>
            <li className="w-full no-underline my-4">
              <div className="px-10 py-3">
                <a href= {pdf} target = "_blank" className="w-full inline-block">
                  WHITEPAPER
                </a>
              </div>
            </li>
            <li className="w-full no-underline my-4">
              <div className="px-10 py-3">
                <a href="https://app.gamut.exchange" target="_blank" className="w-full inline-block">
                  LAUNCH APP 
                </a>
              </div>
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

HeaderLanding.defaultProps = {
  image_alt: "image",
  btInovation: "HOME",
  rootClassName1: "",
  rootClassName: "",
  link_text: "https://example.com",
  btWhitepaper: "WHITEPAPER",
  btBlog: "INNOVATION",
  text: "LAUNCH APP",
  image_src: "/playground_assets/gamut-tlogo-lg.svg",
};

HeaderLanding.propTypes = {
  image_alt: PropTypes.string,
  btInovation: PropTypes.string,
  rootClassName1: PropTypes.string,
  rootClassName: PropTypes.string,
  link_text: PropTypes.string,
  btWhitepaper: PropTypes.string,
  btBlog: PropTypes.string,
  text: PropTypes.string,
  image_src: PropTypes.string,
};

export default HeaderLanding;
