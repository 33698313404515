import React from 'react'

import PropTypes from 'prop-types'

import './gamutbutton.css'

const Gamutbutton = (props) => {
  return (
    <div className={`gamutbutton-app ${props.rootClassName} `}>
      <a href="https://app.gamut.exchange" target="_blank"className="gamutbutton-button button">
        <span className="">{props.text}</span>
      </a>
    </div>
  )
}

Gamutbutton.defaultProps = {
  rootClassName: '',
  text: 'Trade Now',
}

Gamutbutton.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default Gamutbutton
