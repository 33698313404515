import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'
import discord from './docs/Groupdiscord.svg'
import medium from './docs/medium.svg'

const Footer = (props) => {
  return (
    <div className={`footer-container ${props.rootClassName} `}>
      <div className="footer-container1">
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="footer-image"
        />
        <span className="footer-text">{props.text}</span>
        <div className="footer-container2">
          <a href="https://twitter.com/GamutExchange" target="_blank">
            <svg viewBox="0 0 950.8571428571428 1024" className="footer-icon">
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
                className=""
              ></path>
            </svg>
          </a>
          <a href="https://discord.gg/sBZAtdTPyJ" target="_blank">
            <img src={discord} alt="discord_logo" className="footer-icon3"></img>
          </a>
          <a href="https://t.me/GamutExchange" target="_blank">
            <svg viewBox="0 0 1024 1024" className="footer-icon3">
              <path
                d="M679.429 746.857l84-396c7.429-34.857-12.571-48.571-35.429-40l-493.714 190.286c-33.714 13.143-33.143 32-5.714 40.571l126.286 39.429 293.143-184.571c13.714-9.143 26.286-4 16 5.143l-237.143 214.286-9.143 130.286c13.143 0 18.857-5.714 25.714-12.571l61.714-59.429 128 94.286c23.429 13.143 40 6.286 46.286-21.714zM1024 512c0 282.857-229.143 512-512 512s-512-229.143-512-512 229.143-512 512-512 512 229.143 512 512z"
                className=""
              ></path>
            </svg>
          </a>
          <a href="https://medium.com/@gamut_exchange" target="_blank">
            <img src={medium} alt="medium_logo" className="footer-icon4"></img>
          </a>
        </div>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  rootClassName: '',
  text: '2023 Gamut | All Rights Reserved',
  image_src: '/playground_assets/gamut-tlogo-lg.svg',
  image_alt: 'image',
}

Footer.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
}

export default Footer
